/* eslint-disable camelcase */
import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { NotificationContext } from '../notification-bar/NotificationBar';
import { NOTIFICATION_TYPES, COOKIE_USER_LAST_ACTIVITY } from '@mdc/constants';
import { Authenticator, BackendService, logService } from '@mdc/services';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { debounce } from 'lodash';

const UserContext = createContext(Authenticator?.getUser());
const STATES = {
    PENDING: 'pending',
    LOGGED_IN: 'logged in',
    LOGGED_OUT: 'logged out',
    IP_RESTRICTION: 'ip restriction'
};
export const UserProvider = ({ children, value }) => {
    const [user, setUser] = useState(null);
    const [isClient, setIsClient] = useState(false);
    const [isOrganizationAdmin, setIsOrganizationAdmin] = useState();
    const [isOrganizationEnterprise, setIsOrganizationEnterprise] = useState();
    const { notify } = useContext(NotificationContext);

    const getUserState = () => {
        if (!isClient) {
            return STATES.PENDING;
        }
        if (typeof user === 'undefined') {
            return STATES.LOGGED_OUT;
        }

        if (typeof user === 'object' && user !== null) {
            if (user.ipRestriction) {
                return STATES.IP_RESTRICTION;
            }
            return STATES.LOGGED_IN;
        }

        return STATES.PENDING;
    };

    const stateObj = useMemo(() => {
        const state = getUserState();
        return {
            data: user,
            state,
            STATES,
            isPaidUser: state === STATES.LOGGED_IN && user?.paid_user === 1,
            isOrganizationAdmin,
            isOrganizationEnterprise
        };
    }, [user, isClient, isOrganizationAdmin, isOrganizationEnterprise]);

    const onStateChange = (userData) => {
        setUser(userData);
    };

    const onError = ({ message }) => {
        notify({
            message: message,
            type: NOTIFICATION_TYPES.CRITICAL
        });
    };

    const hasMouseCheck = () => {
        const date = Cookies.get(COOKIE_USER_LAST_ACTIVITY);
        const currentDate = new Date();
        const difference = currentDate.getTime() - date;

        //redirect to log out after 15 minutes of inactivity
        if (difference > 900000) {
            Cookies.set(COOKIE_USER_LAST_ACTIVITY, '', { secure: true, expires: new Date(0), sameSite: 'Strict' });
            window.location.href = '/logout';
            return;
        }

        Cookies.set(COOKIE_USER_LAST_ACTIVITY, currentDate.getTime(), { secure: true, sameSite: 'Strict', expires: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000) });
    };

    useEffect(() => {
        setIsClient(true);

        Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, onStateChange);
        Authenticator.events.on(Authenticator.events.topics.ERROR, onError);
        return () => {
            Authenticator.events.off(Authenticator.events.topics.STATE_CHANGE, onStateChange);
            Authenticator.events.off(Authenticator.events.topics.ERROR, onError);
        };
    }, []);

    useEffect(() => {
        handleUserStateChanges();

        if (shouldCheckOrganizationAccess()) {
            checkOrganizationAccess();
        }

        return () => {
            window.removeEventListener('mousemove', hasMouseCheck);
            window.removeEventListener('scroll', hasMouseCheck);
        };
    }, [user, Cookies.get(COOKIE_USER_LAST_ACTIVITY)]);

    const handleUserStateChanges = () => {
        const state = getUserState();
        switch (state) {
            case STATES.LOGGED_OUT:
                resetLastActivityCookie();
                window.removeEventListener('mousemove', hasMouseCheck);
                window.removeEventListener('scroll', hasMouseCheck);
                break;
            case STATES.LOGGED_IN:
                updateLastActivityCookie();
                break;
        }
    };

    const resetLastActivityCookie = () => {
        Cookies.set(COOKIE_USER_LAST_ACTIVITY, '', { secure: true, sameSite: 'Strict', expires: new Date(0) });
    };

    const updateLastActivityCookie = () => {
        const date = Cookies.get(COOKIE_USER_LAST_ACTIVITY);
        const currentDate = new Date();
        if (!date || currentDate.getTime() - date > 900000) {
            if (!date) {
                setNewLastActivityCookie(currentDate);
            } else {
                resetLastActivityCookie();
                window.location.href = '/logout';
            }
        }
        window.addEventListener('mousemove', debounce(hasMouseCheck, 200));
        window.addEventListener('scroll', debounce(hasMouseCheck, 200));
    };

    const setNewLastActivityCookie = (currentDate) => {
        Cookies.set(COOKIE_USER_LAST_ACTIVITY, currentDate.getTime(), { secure: true, sameSite: 'Strict', expires: new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000) });
    };

    const shouldCheckOrganizationAccess = () => {
        return user?.sso_user_id && user?.organization?.organization_id && getUserState() === STATES.LOGGED_IN;
    };

    const checkOrganizationAccess = async () => {
        try {
            const response = await BackendService.postIsOrganizationAdmin({
                userId: user.sso_user_id,
                organizationId: user.organization.organization_id,
            })[0];
            setIsOrganizationAdmin(response.data?.isAdmin || false);
            setIsOrganizationEnterprise(response.data?.isEnterprise || false);
        } catch (e) {
            logService.error(e);
            setIsOrganizationAdmin(undefined);
            setIsOrganizationEnterprise(false);
        }
    };
    return (
        <UserContext.Provider value={value || stateObj}>
            {children}
        </UserContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.element.isRequired,
    value: PropTypes.object
};

export default UserContext;
