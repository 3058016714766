/* eslint-disable camelcase */
import { useMemo } from 'react';
import { dayjs, dateTimeService } from '@mdc/services';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import autoTable from 'jspdf-autotable';
import MDCloudOpswatLogo from '../../../mdc-styles/images/MD-Cloud-logo-black-blue.png';
import MDCloudLogoPDF from '../../../mdc-styles/images/MD-Cloud-logo-PDF.png';
import { PDF_HEADER_VALUES, usePdfData, multiscanPDFData } from './multiscanPDFData';
import { PDF_HEADER_VALUES_ARCHIVE, usePdfArchiveData, archivePDFData } from './ArchiveData';
import { PDF_HEADER_VALUES_CDR, usePdfDataCDR, CDRPdfData } from './CDRData';
import { PDF_HEADER_VALUES_DLP, usePdfCCNDataDLP, usePdfSSNDataDLP, usePdfIPV4DataDLP, usePdfCIDRDataDLP, DLPCCNPdf, DLPCIDRPdf, DLPIPV4Pdf, DLPSSNPdf } from './DLPData';

const jsPDF = typeof window !== 'undefined' ? require('jspdf').jsPDF : null;
const ALLOWED_TYPES = [
    'pdf',
];

const handleMissing = (value) => {
    return value || '-';
};

const getTextColorBody = (result) => {
    switch (result) {
        case 'No threat detected':
            return '#04CB04';
        case 'Unsupported file type':
            return '#FDB00D';
        case 'Infected':
            return '#CB0704';
        case 'Suspicious':
            return '#FDBD0C';
        default:
            return '#3D4A68';
    }
};

const getTextColorArchive = (result) => {
    switch (result) {
        case 'Undetected':
            return '#04CB04';
        case 'Password Protected Document':
            return '#FDB00D';
        case 'Encrypted Archive':
            return '#FDB00D';
        case 'Sensitive Data Found':
            return '#FDB00D';
        case 'Detected':
            return '#CB0704';
        default:
            return '#3D4A68';
    }
};

const getTextColorDlp = (result) => {
    switch (result) {
        case 'High':
            return '#ed6705';
        case 'Medium':
            return '#ed9100';
        case 'Low':
            return '#fdbd0c';
        case 'Not Redacted':
            return '#cb0704';
        case 'Redacted':
            return '#008A01';
        case 'Very Low':
            return '#008A01';
        case 'Very High':
            return '#D00201';
        default:
            return '#3D4A68';
    }
};

const DEFAULT_STYLES = {
    fillColor: [255, 255, 255],
    textColor: { textColor: '#3D4A68', fontStyle: 'bold' },
    lineWidth: 1,
    lineColor: '#E9ECF2',
    font: 'helvetica',
    fontStyle: 'normal',
};

const DEFAULT_COLUMN_STYLES = {
    0: { minCellWidth: 70, halign: 'left', fontStyle: 'bold' },
    1: { minCellWidth: 70 },
    2: { minCellWidth: 50 },
    3: { minCellWidth: 60 },
    4: { minCellWidth: 60 },
    5: { textColor: '#2672FB', fontStyle: 'bold' },
};

const generateBody = (doc, body, pdfHeader, aditionalData, results, hasDlpOrAnonymization, anonymization, detectionScore, duration) => {
    const certainty = handleMissing(results?.dlp_info?.certainty);
    const cropEmbeddedImages = handleMissing(results?.dlp_info?.crop_embedded_images?.result);
    const dataId = handleMissing(results?.data_id);
    const detectionPolicy = handleMissing(results?.dlp_info?.detection_policy?.result);
    const infoData = {
        displayName: handleMissing(aditionalData?.['display_name']),
        fileSize: handleMissing(aditionalData?.['file_size']),
        fileTypeCategory: handleMissing(aditionalData?.['file_type_category']),
        fileTypeDescription: handleMissing(aditionalData?.['file_type_description']),
        fileTypeExtension: handleMissing(aditionalData?.['file_type_extension']),
        md5: handleMissing(aditionalData?.['md5']),
        sha1: handleMissing(aditionalData?.['sha1']),
        sha256: handleMissing(aditionalData?.['sha256']),
        uploadTimestamp: new Date(aditionalData?.['upload_timestamp']),
    };
    const marginValue = hasDlpOrAnonymization ? 390 : 270;
    const metadataRemoval = handleMissing(results?.dlp_info?.metadata_removal?.result);
    const recursiveProcessing = handleMissing(results?.dlp_info?.recursive_processing?.result);
    const redact = handleMissing(results?.dlp_info?.redact?.result);
    const timestamp = Date.parse(infoData.uploadTimestamp) ? infoData.uploadTimestamp : '-';
    const watermark = handleMissing(results?.dlp_info?.watermark?.result);

    autoTable(doc, {
        didParseCell: (value) => {
            const result = value.cell.raw;
            const textColor = getTextColorBody(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeader,
        body: body,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: marginValue },
        didDrawPage: (value) => {
            doc.setTextColor('#3D4A68');
            doc.setFontSize(10);
            doc.setFontSize(9);
            doc.setTextColor('#707DA1');
            doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
            doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
            doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
            doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
            doc.setDrawColor('#E9ECF2');
            doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
            doc.setFontSize(10);
            doc.setTextColor('#707DA1');
            doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
            doc.setFontSize(13);
            doc.setTextColor('#3D4A68');
            doc.setFont('helvetica', 'bold');
            doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            value.settings.margin.top = 90;
            if (value.pageNumber === 1) {
                doc.setFontSize(13);
                doc.setFont('helvetica', 'bold');
                doc.setTextColor('#3D4A68');
                doc.text('Data ID: ' + dataId.toString(), 30, 90);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('File Details: ', 30, 110);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('File Name: ', 30, 120);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.displayName.toString(), 71, 120);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('File Size: ', 30, 130);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.fileSize.toString(), 64, 130);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('Extension Type Category: ', 30, 140);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.fileTypeCategory.toString(), 126, 140);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('File Type Desciption: ', 30, 150);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.fileTypeDescription.toString(), 108, 150);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('File Type Extension: ', 30, 160);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.fileTypeExtension.toString(), 106, 160);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('MD5: ', 30, 170);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.md5.toString(), 50, 170);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('SHA1: ', 30, 180);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.sha1.toString(), 55, 180);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('SHA256: ', 30, 190);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(infoData.sha256.toString(), 64, 190);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('Upload Timestamp: ', 30, 200);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(timestamp.toString(), 104, 200);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('Duration: ', 30, 210);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(duration.toString(), 66, 210);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                if (hasDlpOrAnonymization) {
                    doc.text('DLP Details: ', 30, 230);
                    doc.setFontSize(10);
                    doc.text('Certainty: ', 30, 240);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(certainty.toString(), 66, 240);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Detection Threats Score: ', 30, 250);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(detectionScore.toString(), 120, 250);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Anonymization: ', 30, 260);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(anonymization.toString(), 88, 260);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Detection Policy: ', 30, 270);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(detectionPolicy.toString(), 92, 270);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Crop Embedded Images: ', 30, 280);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(cropEmbeddedImages.toString(), 120, 280);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Metadata Removal: ', 30, 290);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(metadataRemoval.toString(), 100, 290);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Recursive Processing: ', 30, 300);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(recursiveProcessing.toString(), 112, 300);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Redacted: ', 30, 310);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(redact.toString(), 67, 310);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Watermark: ', 30, 320);
                    doc.setFontSize(10);
                    doc.setTextColor('#3D4A68', 'italic');
                    doc.setFont('helvetica', 'italic');
                    doc.text(watermark.toString(), 72, 320);
                    doc.setFontSize(13);
                    doc.setFont('helvetica', 'bold');
                    doc.setTextColor('#3D4A68');
                    doc.text('Report Source:', 30, 350);
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(8);
                    doc.setTextColor('#2672FB');
                    doc.textWithLink(`${window?.location?.href?.replace('http://localhost:8801', 'https://metadefender.opswat.com')}`, 30, 360, { URL: `${window?.location?.href}` });
                    doc.setFontSize(13);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Metascan Multiscan', 30, 380);
                } else {
                    doc.setFontSize(13);
                    doc.setFont('helvetica', 'bold');
                    doc.setTextColor('#3D4A68');
                    doc.text('Report Source:', 30, 230);
                    doc.setFont('helvetica', 'normal');
                    doc.setFontSize(8);
                    doc.setTextColor('#2672FB');
                    doc.textWithLink(`${window?.location?.href?.replace('http://localhost:8801', 'https://metadefender.opswat.com')}`, 30, 240, { URL: `${window?.location?.href}` });
                    doc.setFontSize(13);
                    doc.setTextColor('#3D4A68');
                    doc.setFont('helvetica', 'bold');
                    doc.text('Metascan Multiscan', 30, 260);
                }
            }
        }
    });
};

const generateBodyCDR = (doc, bodyCDR, pdfHeaderCDR) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
        },
        head: pdfHeaderCDR,
        body: bodyCDR,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                doc.setTextColor('#3D4A68');
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('Deep CDR', 30, value.settings.startY - 25);
                value.settings.margin.top = 90;
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('After Data Sanitization', 30, value.settings.startY - 15);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const generateBodyArchive = (doc, bodyArchive, pdfHeaderArchive, results) => {
    let lineCancel = false;
    const numberOfExtractedFiles = results?.extracted_files?.files_in_archive?.length || '...';

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
            const result = value.cell.raw;
            const textColor = getTextColorArchive(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeaderArchive,
        body: bodyArchive,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setTextColor('#3D4A68');
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('Extracted files', 30, value.settings.startY - 25);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('File in packages: ', 30, value.settings.startY - 15);
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'italic');
                doc.setFont('helvetica', 'italic');
                doc.text(numberOfExtractedFiles.toString(), 92, value.settings.startY - 15);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const generateBodyDLP = (doc) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
        },
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                doc.setTextColor('#3D4A68');
                doc.setFontSize(14);
                doc.setFont('helvetica', 'bold');
                doc.text('PROACTIVE DLP', 30, value.settings.startY + 25);
            }
        }
    });
};

const generateBodyCCNDLP = (doc, bodyCCNDLP, pdfHeaderDLP) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
            const result = value.cell.raw;
            const textColor = getTextColorDlp(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeaderDLP,
        body: bodyCCNDLP,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                value.settings.margin.top = 90;
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('CREDIT CARD NUMBER', 30, value.settings.startY - 20);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const generateBodySSNDLP = (doc, bodySSNDLP, pdfHeaderDLP) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
            const result = value.cell.raw;
            const textColor = getTextColorDlp(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeaderDLP,
        body: bodySSNDLP,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                value.settings.margin.top = 90;
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('SOCIAL SECURITY NUMBER ', 30, value.settings.startY - 20);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const generateBodyIPV4DLP = (doc, bodyIPV4DLP, pdfHeaderDLP) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
            const result = value.cell.raw;
            const textColor = getTextColorDlp(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeaderDLP,
        body: bodyIPV4DLP,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                value.settings.margin.top = 90;
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('IPV4 ADDRESS OR SUBNET MASK', 30, value.settings.startY - 20);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const generateBodyCIDRDLP = (doc, bodyCIDRDLP, pdfHeaderDLP) => {
    let lineCancel = false;

    autoTable(doc, {
        didParseCell: (value) => {
            if (!lineCancel) {
                value.settings.startY = value.settings.startY + 40;
                lineCancel = true;
            }
            const result = value.cell.raw;
            const textColor = getTextColorDlp(result);
            value.cell.styles.textColor = textColor;
        },
        head: pdfHeaderDLP,
        body: bodyCIDRDLP,
        theme: 'grid',
        showHead: true,
        styles: DEFAULT_STYLES,
        columnStyles: DEFAULT_COLUMN_STYLES,
        margin: { top: 100 },
        didDrawPage: (value) => {
            if (value.pageNumber === 1) {
                value.settings.margin.top = 90;
                doc.setFontSize(10);
                doc.setTextColor('#3D4A68', 'bold');
                doc.setFont('helvetica', 'bold');
                doc.text('CLASSLESS INTER-DOMAIN ROUTING', 30, value.settings.startY - 20);
            } else {
                doc.setFontSize(9);
                doc.setTextColor('#707DA1');
                doc.text(`© ${dayjs().format('YYYY')} OPSWAT, Inc. All rights reserved. OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device,`, 30, doc.internal.pageSize.getHeight() - 20);
                doc.text('and the OPSWAT logo are trademarks of OPSWAT, Inc', 30, doc.internal.pageSize.getHeight() - 10);
                doc.addImage(MDCloudLogoPDF, 'svg', 0, 0, doc.internal.pageSize.getWidth(), 17);
                doc.addImage(MDCloudOpswatLogo, 'svg', 30, 30, 64, 27);
                doc.setDrawColor('#E9ECF2');
                doc.line(30, 60, doc.internal.pageSize.getWidth() - 30, 61);
                doc.setFontSize(10);
                doc.setTextColor('#707DA1');
                doc.text(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`, doc.internal.pageSize.getWidth() - doc.getTextWidth(`Generated ${dayjs().format('YYYY/MM/DD')} at ${dayjs().format('h:mm')} UTC`) - 30, 35);
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(13);
                doc.setTextColor('#3D4A68');
                doc.setFont('helvetica', 'bold');
                doc.text('Analysis Report', doc.internal.pageSize.getWidth() - doc.getTextWidth('Analysis Report') - 30, 48);
            }
        }
    });
};

const PDFExport = ({ types, data, fileName, aditionalData, results, extractedFiles, sanitizationResultsDetails, dlpResults }) => {
    const { t, ready, i18n } = useTranslation();
    const pdfHeader = PDF_HEADER_VALUES(t);
    const pdfHeaderCDR = PDF_HEADER_VALUES_CDR(t);
    const pdfHeaderArchive = PDF_HEADER_VALUES_ARCHIVE(t);
    const pdfHeaderDLP = PDF_HEADER_VALUES_DLP(t);
    const lang = i18n?.language?.split('-')[0] || 'en';
    const duration = dateTimeService.getDurationHumanized(data?.total_time, lang);
    const pdfData = usePdfData(data);
    const pdfDataArchive = usePdfArchiveData(extractedFiles, results);
    const pdfDataCDR = usePdfDataCDR(sanitizationResultsDetails);
    const pdfCCNDataDLP = usePdfCCNDataDLP(dlpResults);
    const pdfSSNDataDLP = usePdfSSNDataDLP(dlpResults);
    const pdfIPV4DataDLP = usePdfIPV4DataDLP(dlpResults);
    const pdfCIDRDataDLP = usePdfCIDRDataDLP(dlpResults);

    const generatePDF = () => {
        const doc = new jsPDF({
            unit: 'px',
        });
        doc.setFont('helvetica', 'normal');

        const body = multiscanPDFData(pdfData);
        const bodyArchive = archivePDFData(pdfDataArchive);
        const bodyCDR = CDRPdfData(pdfDataCDR);
        const bodyCCNDLP = DLPCCNPdf(pdfCCNDataDLP);
        const bodyCIDRDLP = DLPCIDRPdf(pdfCIDRDataDLP);
        const bodyIPV4DLP = DLPIPV4Pdf(pdfIPV4DataDLP);
        const bodySSNDLP = DLPSSNPdf(pdfSSNDataDLP);
        const dlpBodyLengths = [bodyCCNDLP?.length, bodyCIDRDLP?.length, bodyIPV4DLP?.length, bodySSNDLP?.length];
        const hasDlpData = dlpBodyLengths.some(element => element > 0);
        const anonymization = handleMissing(results?.dlp_info?.anonymization?.result);
        const hasDlpOrAnonymization = hasDlpData || (anonymization !== '-');
        const detectionScore = dlpBodyLengths.reduce((score, value) => (score + (value || 0)), 0);

        if (body?.length > 0) {
            generateBody(doc, body, pdfHeader, aditionalData, results, hasDlpOrAnonymization, anonymization, detectionScore, duration);
        }

        if (bodyCDR?.length > 0) {
            generateBodyCDR(doc, bodyCDR, pdfHeaderCDR);
        }

        if (bodyArchive !== undefined) {
            generateBodyArchive(doc, bodyArchive, pdfHeaderArchive, results);
        }

        if (hasDlpData) {
            generateBodyDLP(doc);
        }

        if (bodyCCNDLP?.length > 0) {
            generateBodyCCNDLP(doc, bodyCCNDLP, pdfHeaderDLP);
        }

        if (bodySSNDLP?.length > 0) {
            generateBodySSNDLP(doc, bodySSNDLP, pdfHeaderDLP);
        }

        if (bodyIPV4DLP?.length > 0) {
            generateBodyIPV4DLP(doc, bodyIPV4DLP, pdfHeaderDLP);
        }

        if (bodyCIDRDLP?.length > 0) {
            generateBodyCIDRDLP(doc, bodyCIDRDLP, pdfHeaderDLP);
        }

        doc.save(`${fileName}.pdf`);
    };

    const exportDataDom = useMemo(() => (type) => {
        if (type === 'pdf') {
            return (
                <button onClick={generatePDF} className='downloadSanitizedButton pl-0 btn btn-link'>
                    <i className='icon icon-down' />
                    {t('Export')} {t(type.toUpperCase())}
                </button>
            );
        }
        {
            return (
                <a href={'href'} download={`test.${type}`} className='downloadSanitizedButton pl-0 btn btn-link'>
                    <i className='icon icon-down' />
                    {t('Export')} {t(type.toUpperCase())}
                </a>
            );
        }
    }, [fileName, t]);


    const buttonsDom = useMemo(() => {
        return types?.map((type) => {
            if (ALLOWED_TYPES.includes(type?.toLowerCase())) {
                return exportDataDom(type);
            }
        });
    }, [types]);

    if (!ready) {
        return;
    }

    return <div className='actions'>
        {buttonsDom}
    </div>;
};

PDFExport.propTypes = {
    types: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    fileName: PropTypes.string.isRequired,
    extractedFiles: PropTypes.string.isRequired,
    sanitizationResultsDetails: PropTypes.string.isRequired,
    aditionalData: PropTypes.string,
    results: PropTypes.string,
    dlpResults: PropTypes.string
};

export default PDFExport;
