import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SortButton, { SORT_STATES } from '../table/sort-button/SortButton';
import classNames from 'classnames';
import { onSortHandler, getSortDirection } from '../sortTable/SortTable';

import './PlainTableHeader.scss';

const PlainTableHeader = (props) => {
    const { t, ready } = useTranslation();
    const { columnsData, isCollapsed, hasCollapseAll, onCollapseAllClick, collapseAllDom, hasSorting, isEntireRowExpandable } = props;
    const [sort, setSort] = useState({ accessor: null, direction: null });

    const trClassNames = classNames({
        isEntireRowExpandable: isEntireRowExpandable
    });

    const onSort = (accessor, state) => {
        onSortHandler(sort, setSort, props.onSort, accessor, state);
    };


    const handleExpand = () => {
        if (isEntireRowExpandable) {
            onCollapseAllClick();
        }
    };

    const theadData = useMemo(() => {
        return <tr className={trClassNames} onClick={handleExpand}>
            {columnsData.map((data) => {
                const isSorted = sort.accessor === data.accessor;
                let sortDirection = getSortDirection(sort.state);
                const sortButtonState = isSorted ? sortDirection : SORT_STATES.INACTIVE;
                const SortButtonComponent = (hasSorting || data.hasSorting ?
                    <SortButton
                        state={sortButtonState}
                        onSort={onSort.bind(null, data.accessor)} /> : null);

                const spanDom = <span className='thCellContent'>
                    {typeof data.Header === 'string' ?
                        <span dangerouslySetInnerHTML={{ __html: t(data.Header) }} /> :
                        data.Header}
                    {SortButtonComponent}
                </span>;

                return <th
                    key={data.accessor}
                    className={`cell-${data.accessor} ${data.accessor === 'meta' && 'cell-collapseAll'}`}
                >
                    {
                        data.accessor === 'meta' ? collapseAllDom : spanDom
                    }
                </th>;
            })}
        </tr>;
    }, [columnsData, isCollapsed, hasCollapseAll, onCollapseAllClick, sort, isEntireRowExpandable]);

    if (!ready) {
        return null;
    }

    return <thead>
        {theadData}
    </thead>;

};

PlainTableHeader.propTypes = {
    columnsData: PropTypes.array,
    isCollapsed: PropTypes.bool,
    hasCollapseAll: PropTypes.bool,
    onCollapseAllClick: PropTypes.func,
    collapseAllDom: PropTypes.node,
    hasSorting: PropTypes.bool,
    onSort: PropTypes.func,
    isEntireRowExpandable: PropTypes.bool
};

export default PlainTableHeader;
