/* eslint-disable no-useless-escape */
export default (t) => {
    const scanInfo = {
        i2: {
            status: t('Suspicious'),
            alert: t('The file was classified as having a possible threat but it wasn\'t identified as a specific threat')
        },
        i3: {
            status: t('Failed to scan'),
        },
        i4: {
            status: t('Cleaned'),
            alert: t('A threat was found and the file is cleaned')
        },
        i5: {
            status: t('Unknown'),
            alert: t('Unknown signature')
        },
        i6: {
            status: t('Quarantined'),
            alert: t('The file you uploaded is quarantined')
        },
        i7: {
            status: t('Skipped Clean'),
            alert: t('The scan process is skipped because this file type is allow-listed')
        },
        i8: {
            status: t('Skipped Dirty'),
            alert: t('The scan process is skipped because this file type is denylisted')
        },
        i9: {
            status: t('Exceeded Archive Depth'),
            alert: t('Exceeded maximum archive depth. Please upload archives that have up to five nestings')
        },
        i10: {
            status: t('Not Scanned / No scan results'),
            alert: t('Scan is skipped by the engine either due to update or other engine-specific reason. If the scan is disabled, this will be the final result')
        },
        i11: {
            status: t('Aborted'),
            alert: t('The scan was aborted by the server')
        },
        i12: {
            status: t('Encrypted Archive'),
            alert: t('The file seems to be encrypted. If this is an encrypted archive, please check <a href="https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref#file-upload" target="_blank">Scanning a file by file upload</a>, from our <a href="https://docs.opswat.com/mdcloud" target="_blank">Online Help</a> documentation')
        },
        i13: {
            status: t('Exceeded Archive Size'),
            alert: t('The extracted archive is too large to scan')
        },
        i14: {
            status: t('Exceeded Archive File Number'),
            alert: t('The scan was not completed because the number of files in the archive exceeds the supported limit')
        },
        i15: {
            status: t('Password Protected Document'),
            alert: t('This is a password-protected document. To scan it, please check <a href="https://docs.opswat.com/mdcloud/metadefender-cloud-api-v4/ref#file-upload" target="_blank">Scanning a file by file upload</a>, from our <a href="https://docs.opswat.com/mdcloud" target="_blank">Online Help</a> documentation')
        },
        i16: {
            status: t('Exceeded Archive Timeout'),
            alert: t('The archive process reached the given timeout value')
        },
        i17: {
            status: t('Mismatch'),
            alert: t('The file\'s extension does not match the detected file type. Only applicable when using workflows')
        },
        i18: {
            status: t('Potentially Vulnerable File'),
            alert: t('Possible vulnerability detected for the applied file because the extension is not supported by one or more engines')
        },
        i19: {
            status: t('Canceled'),
            alert: t('The file scan was canceled because it failed to scan too many times')
        },
        i20: {
            status: t('Sensitive Data Found'),
            alert: t('Data loss prevention engine found sensitive data')
        },
        i21: {
            status: t('Yara Rule Matched'),
            alert: t('The analysis data matches a YARA rule')
        },
        i22: {
            status: t('Potentially unwanted program'),
            alert: t('Returned by engines with PUA detection capabilities')
        },
        i23: {
            status: t('Filetype not supported'),
            alert: t('The engine does not support scanning this file type. Certain engines only scan specific file types such as executable files or documents')
        },
        i253: {
            status: t('Exceeded Limits'),
            alert: t('Exceeded Limits')
        }
    };

    return {
        resultStatus: Object.fromEntries(
            Object.entries(scanInfo).map(([key, { status }]) => [key, status])
        ),
        scanAlertInfo: Object.fromEntries(
            Object.entries(scanInfo).map(([key, { alert }]) => [key, alert || ''])
        )
    };
};
