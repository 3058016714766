import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { getRandomID } from '@mdc/services';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import './VoteButton.scss';

const VoteButton = ({ type, handleVote, alreadyVoted, disabled, buttonText }) => {
    const [voteInfoTooltipId] = useState(`VotingInfo${getRandomID(5)}`);
    const { t, ready } = useTranslation();

    const buttonClassName = classNames({
        alreadyVoted: alreadyVoted && alreadyVoted === type,
        upVoteButton: type === 'up',
        downVoteButton: type === 'down'
    }, 'voteButton');

    const voteButton = useMemo(() => {
        const button = <i className={`icon icon-thumbs-${type}`} />;
        return (<>
            <Button variant='link' className={buttonClassName} onClick={handleVote} disabled={disabled}>
                {button}
                {buttonText}
            </Button>
        </>);

    }, [disabled, alreadyVoted, type, t]);

    const VoteInfoTooltipContent = t('<strong>Clean</strong>: legitimate file with no malicious behavior. <br/> <strong>Malicious</strong>: dangerous file which might harm your computer, leak data or has unintended behavior. <hr/> The voting opinion is independent of scan results displayed');

    if (!ready) {
        return null;
    }

    return (
        <div className="VoteButtonWrapper">
            <div className='buttonsWrapper'>
                {voteButton}
                <Tooltip id={voteInfoTooltipId} className='voteInfoTooltip' variant='dark' delayHide={300} place={'bottom'}
                    content={<span dangerouslySetInnerHTML={{ __html: VoteInfoTooltipContent }}></span>}
                >
                </Tooltip>
            </div>
        </div>
    );
};

VoteButton.propTypes = {
    type: PropTypes.string.isRequired,
    handleVote: PropTypes.func.isRequired,
    alreadyVoted: PropTypes.string,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string
};

export default VoteButton;
