export const ADVANCED_OPTIONS_DEFAULTS = { 'multiscan': 'true', 'cdr': 'true' };
export const ADVANCED_OPTIONS_KEY = 'advancedOptions';
export const MULTISCAN_LAST_ADVANCED_OPTIONS_KEY = 'multiscanLastAdvancedOptions';
export const SANDBOX_LAST_ADVANCED_OPTIONS_KEY = 'sandboxLastAdvancedOptions';
export const PAID_ONLY_HEADERS = ['privateprocessing'];
export const API_KEY = 'ngStorage-apiKey';
export const CDN_PATH = 'https://mdcl-cdn.opswat.com/1.91.0-b5f36f073';
export const HUBSPOT_PORTAL_ID = '2818205';
export const STATUS_PAGE = {
    url: 'statuspage.io/api/v2/',
    qa: {
        pageId: 'qvy4tlwl3cbb',
        cloudGroupId: 'ggl6ls71wtk5'
    },
    prod: {
        pageId: '7mf88krcygn3',
        cloudGroupId: '7ms269vkvq43'
    }
};
export const HUBSPOT_FORMS = {
    /* eslint-disable camelcase */
    en_US: {
        OUTBREAK_FORM_ID: '630577fd-0a7c-4ae5-9025-646447b43ec6',
        FORM_ID: '0cc528f9-d88d-4776-a051-019880a57d1e',
        SANITIZATION_FORM_ID: '1c3b2eb8-a31b-47d3-b0fd-90c768ca467a',
        MARKETSHARE_FORM_ID: 'e2a49129-4527-41ca-b3f0-6c7158bd05fe',
        SANDBOX_FORM_ID: '3309e397-33bf-43d1-bcee-0650a845296b'
    },
    ja_JP: {
        OUTBREAK_FORM_ID: '630577fd-0a7c-4ae5-9025-646447b43ec6',
        FORM_ID: 'aba178c3-e99d-48ed-bc56-35bde77d2595',
        SANITIZATION_FORM_ID: 'd85caac1-1295-4f63-86c7-b15e6ef90bc4',
        MARKETSHARE_FORM_ID: 'e2a49129-4527-41ca-b3f0-6c7158bd05fe',
        SANDBOX_FORM_ID: '4f73952c-d5ce-4181-b209-70fc409bbf28'
    }
};
