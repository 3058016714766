import { SORT_STATES } from '../table/sort-button/SortButton';


export const onSortHandler = (sort, setSort, onSortCallback, accessor, state) => {
    if (sort?.accessor === accessor && sort?.state === state) {
        state = SORT_STATES.INACTIVE;
    }
    onSortCallback?.({ accessor, state });
    setSort({ accessor, state });
};

export const getSortDirection = (state) => {
    switch (state) {
        case SORT_STATES.UP:
            return SORT_STATES.UP;
        case SORT_STATES.DOWN:
            return SORT_STATES.DOWN;
        default:
            return SORT_STATES.INACTIVE;
    }
};
